const mapboxLibraryLink: string = 'https://api.mapbox.com/mapbox-gl-js/v1.13.2/mapbox-gl.js';

export function loadMapboxGL(): Promise<void> {
    return new Promise( ( resolve, reject ) => {
        if ( typeof window.mapboxgl !== 'undefined' ) {
            resolve();
            return;
        }

        const script = document.createElement( 'script' );
        script.src = mapboxLibraryLink;

        script.onload = () => resolve();
        script.onerror = () => reject();

        document.head.appendChild( script );
    } );
}
