function initPrintable() {
    document.addEventListener( 'click', function( event ) {
        if ( ( !event.target.parentElement || !event.target.parentElement.classList.contains( 'print-page' ) ) &&
             ( !event.target || !event.target.classList.contains( 'print-page' ) ) ) {
            return;
        }

        window.print();
    } );
}

export default initPrintable;
