import { loadCss } from '../assets/css';
import { loadScript } from '../assets/scripts';
import { getPropertyMarkerType } from './property/marker';
import { hasUserContactedProperty, hasUserSavedProperty } from './storage';
import { addMarkerToMap } from './markers/load';
import { loadMapboxGL } from './mapbox';

function initMap( container ) {
    var displayMap = function( element ) {
        var el = element;

        var townName = el.dataset.townName;
        var provinceName = el.dataset.townProvinceName;
        var lat = el.dataset.lat;
        var lng = el.dataset.lng;
        var townLng = el.dataset.townLng;
        var fromAction = el.dataset.fromAction;
        var address = el.dataset.address;
        var zipcode = el.dataset.zipcode;
        var country = el.dataset.country;
        var townLat = el.dataset.townLat;
        var note = el.dataset.addControlnote;
        var msg = el.dataset.msg;

        var currentURLParams = new URLSearchParams( window.location.search );

        if ( currentURLParams.get( 'target' ) === 'propertyMap' ) {
            fromAction = 'property-list';
        }

        async function displayLocationUsingMaptiler( lat, lng, isExact ) {
            var load = function load() {
                if ( window.propertyMap ) {
                    window.propertyMap.remove();
                    window.propertyMap = null;
                }

                window.propertyMap = new mapboxgl.Map( {
                    container: document.getElementById( 'map' ),
                    style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=1gSrAVaVn53NYX4186Wo',
                    dragRotate: false,
                    dragPan: isExact,
                    doubleClickZoom: false,
                    center: [ lng, lat ],
                    zoom: isExact ? 12 : 10,
                    minZoom: isExact ? 4 : 4,
                    maxZoom: isExact ? 17 : 10,
                    scrollZoom: false,
                    touchZoomRotate: isExact,
                    touchPitch: false,
                    interactive: isExact,
                } );

                window.propertyMap.on( 'load', function() {
                    var icon = getPropertyMarkerType( {
                        precision: element.dataset.coordPrecision,
                        saved: hasUserSavedProperty( element.dataset.propertyId ),
                        contacted: hasUserContactedProperty( element.dataset.propertyId ),
                        visited: true,
                        hover: false,
                    } );

                    addMarkerToMap( window.propertyMap, icon )
                        .then( function() {
                            window.propertyMap.addSource( 'property', {
                                type: 'geojson',
                                data: {
                                    type: 'FeatureCollection',
                                    features: [
                                        {
                                            id: element.dataset.propertyId,
                                            type: 'Feature',
                                            geometry: {
                                                type: 'Point',
                                                coordinates: [
                                                    lng,
                                                    lat,
                                                ],
                                            },
                                            properties: {
                                                id: element.dataset.propertyId,
                                                icon: icon,
                                                type: 'property',
                                            },
                                        },
                                    ],
                                },
                            } );

                            window.propertyMap.addLayer( {
                                id: 'property',
                                type: 'symbol',
                                source: 'property',
                                layout: {
                                    'icon-image': '{icon}',
                                },
                            } );
                        } );

                    var controls = new mapboxgl.NavigationControl( {
                        showCompass: false,
                    } );

                    window.propertyMap.addControl( controls, 'top-left' );

                    var mobile = ( navigator.maxTouchPoints || 'ontouchstart' in document );

                    if ( mobile && isExact ) {
                        window.propertyMap.dragPan.disable();

                        window.propertyMap.on( 'touchstart', function( event ) {
                            const e = event.originalEvent;
                            if ( e && 'touches' in e ) {
                                if ( e.touches.length > 1 ) {
                                    window.propertyMap.dragPan.enable();
                                } else {
                                    window.propertyMap.dragPan.disable();
                                }
                            }
                        } );
                    }

                    if ( !isExact ) {
                        window.propertyMap.dragPan.disable();
                    }

                    if ( isExact && mobile ) {
                        note = msg;
                    }

                    if ( note ) {
                        var popup = new mapboxgl.Popup( {
                            closeOnClick: false,
                            closeOnMove: isExact,
                            maxWidth: 500,
                            offset: {
                                bottom: [ 0, -35 ],
                            },
                        } );

                        popup.setLngLat( [ lng, lat ] );
                        popup.setHTML( note );
                        popup.addTo( window.propertyMap );
                    }
                } );
            };

            if ( typeof window.mapboxgl === 'undefined' ) {
                loadCss( 'https://api.mapbox.com/mapbox-gl-js/v1.13.2/mapbox-gl.css' );
                await loadMapboxGL();
                load();
            } else {
                load();
            }
        }

        function displayLocationUsingGoogleMaps( lat, lng ) {
            var load = function load() {
                var center = {
                    lat: lat,
                    lng: lng,
                };

                var pos = new google.maps.LatLng( center.lat, center.lng );

                var mapOptions = {
                    mapId: 'item_map',
                    zoom: 15,
                    center: pos,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    zoomControl: true,
                    scaleControl: true,
                    draggable: true,
                    scrollwheel: false,
                    streetViewControl: false,
                    panControl: false,
                    rotateControl: false,
                    mapTypeControl: false,
                };

                var map = new google.maps.Map( element, mapOptions );

                const markerImg = document.createElement( 'img' );
                markerImg.src = 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png';

                const marker = new google.maps.marker.AdvancedMarkerElement( {
                    map: map,
                    position: pos,
                    content: markerImg,
                } );

                if ( note ) {
                    var infowindow = new google.maps.InfoWindow( {
                        content: note,
                    } );

                    infowindow.open( map, marker );

                    google.maps.event.addListener( marker, 'click', function() {
                        infowindow.open( map, marker );
                    } );
                }
            };

            if ( typeof google === 'undefined' || typeof google.maps === 'undefined' ) {
                const url = new URL( 'https://maps.googleapis.com/maps/api/js' );
                url.searchParams.set( 'key', 'AIzaSyCQd0rbfcGuoTJQVsUEEsfbGl7Nh6izbHs' );
                url.searchParams.set( 'libraries', 'marker' );

                loadScript( url.toString(), function() {
                    load();
                } );
            } else {
                load();
            }
        }

        function displayExactLocation( provider, lat, lng ) {
            if ( provider === 'maptiler' ) {
                displayLocationUsingMaptiler( lat, lng, true );
            } else {
                displayLocationUsingGoogleMaps( lat, lng );
            }
        }

        function displayApproxLocation( lat, lng ) {
            displayLocationUsingMaptiler( lat, lng, false );
        }

        if ( lat && lng ) {
            var provider;

            if ( fromAction.startsWith( 'property-' ) ) {
                provider = 'maptiler';
            } else {
                provider = 'google';
            }

            displayExactLocation( provider, lat, lng );
            return;
        }

        if ( !address ) {
            displayApproxLocation( townLat, townLng );
            return;
        }

        var url = new URL( 'https://maps.googleapis.com/maps/api/geocode/json' );
        url.searchParams.set( 'key', 'AIzaSyCQd0rbfcGuoTJQVsUEEsfbGl7Nh6izbHs' );
        url.searchParams.set( 'address', address );

        if ( country ) {
            url.searchParams.set( 'componentRestrictions[country]', country );
        }

        if ( zipcode ) {
            url.searchParams.set( 'componentRestrictions[postalCode]', zipcode.toString() );
        }

        if ( townName ) {
            url.searchParams.set( 'address', address + ',' + townName );
        }

        if ( provinceName ) {
            url.searchParams.set( 'address', address + ',' + provinceName );
        }

        fetch( url )
            .then( function( response ) {
                if ( !response.ok ) {
                    throw new Error( 'failed getting geocode' );
                }
                return response.json();
            } )
            .then( function( result ) {
                if ( result.status === 'OK' && result.results.length > 0 ) {
                    var loc = result.results[ 0 ].geometry.location;
                    displayExactLocation( 'google', loc.lat, loc.lng );
                } else if ( townLat && townLng ) {
                    displayApproxLocation( townLat, townLng );
                }
            } );
    };

    container.querySelectorAll( '.twc__map' ).forEach( ( el ) => displayMap( el ) );
}

function itemDetailMaps() {
    document.addEventListener( 'click', function( event ) {
        if ( !event.target || event.target.disabled || !event.target.parentElement || ( !event.target.dataset.action && event.target.dataset.action !== 'map-launcher' ) ) {
            return;
        }

        var url = event.target.dataset.mapUrl;

        if ( !url ) {
            return;
        }

        event.target.disabled = true;
        event.target.classList.add( 'twc__maplauncher--loading' );

        http( url )
            .then( function( response ) {
                if ( !response.ok ) {
                    throw new Error( 'fetching ' + url + ' failed with status ' + response.status );
                }
                return response.text();
            } )
            .then( function( html ) {
                var map = document.createElement( 'div' );
                map.innerHTML = html;

                event.target.parentElement.appendChild( map.firstElementChild );

                initMap( document.querySelector( '.pagepopup__contentinner' ) );
                event.target.remove();
            } )
            .catch( function( err ) {
                event.target.disabled = false;
                event.target.classList.remove( 'twc__maplauncher--loading' );
                throw err;
            } );
    } );

    window.addEventListener( 'twc:init-map', function( event ) {
        var container = event.detail.container;
        initMap( container );
    } );
}

export default itemDetailMaps;
