var redTypes = [ 'exact', 'street', 'approx' ];
var pinTypes = [ 'exact', 'street' ];

const defaultState = {
    precision: 'approx',
    contacted: false,
    saved: false,
    hover: false,
    visited: true,
    active: false,
    discarded: false,
};

function getPropertyMarkerType( property = defaultState ) {
    property = Object.assign( {}, defaultState, property );

    var pinType = getPinType( property );
    var pinColor = getPinColor( property );
    var prefix = `${pinType}-${pinColor}`;
    var icon = '';

    const getSuffix = () => {
        if ( property.contacted && property.hover ) {
            return '-contacted-hover';
        }

        if ( property.saved && property.hover ) {
            return '-saved-hover';
        }

        if ( property.contacted ) {
            return '-contacted';
        }

        if ( property.saved ) {
            return '-saved';
        }

        if ( property.hover ) {
            return '-hover';
        }

        return '';
    };

    var suffix = getSuffix();

    if ( property.discarded ) {
        icon += `${prefix}-discarded`;
    } else if ( !property.active && !property.visited ) {
        icon += `${prefix}-base`;
    } else if ( property.active ) {
        icon += `${prefix}-active`;
    } else if ( property.visited ) {
        icon += `${prefix}-visited`;
    }

    if ( suffix.length > 0 ) {
        icon += suffix;
    }

    return icon;
}

function getPinColor( property ) {
    if ( property.discarded ) {
        return 'grey';
    }

    if ( property.contacted ) {
        return 'green';
    }

    if ( redTypes.includes( property.precision ) ) {
        return 'red';
    }

    return 'blue';
}

function getPinType( property ) {
    if ( pinTypes.includes( property.precision ) ) {
        return 'pin';
    }
    return 'dot';
}

export {
    getPropertyMarkerType,
    getPinColor,
    getPinType,
};
