import initListExpanders from '../list-expander';
import LocationMap from '../locationsmap';
import Logger from '../logging/logger';

function onPopupOpenLocation( event ) {
    var detail = event.detail || {};

    Logger.info( 'Event Triggered: onPopupOpenLocation', detail );

    var container = detail.container || document;

    const mapContainer = document.querySelector( '.locationmap__container' );
    if ( mapContainer ) {
        new LocationMap( mapContainer );
    }

    initListExpanders( container );
}

export default onPopupOpenLocation;
