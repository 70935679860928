import { parseEscapedJSONString } from '../json/parse';
import LocationIndicator from '../maps/components/location-indicator';
import LocationFeature from '../maps/models/feature';
import { getLanguage, isTouchDevice } from '../user/device-info';
import { navigateTo } from '../http/redirect';
import PoiSelector from './components/poi-selector';
import { MarkerGroup } from './models';
import { Municipality, Province } from '../locations';
import { loadMapboxGL } from '../maps/mapbox';

interface SlugResponse {
	Slug: string;
}

class LocationMap {
    _container: HTMLElement;
    _currentLanguage: string;
    _mapElement: HTMLElement;
    _map: mapboxgl.Map;

    _currentLocationID: number;
    _currentCursorTarget: LocationFeature | null = null;
    _locationIndicator: LocationIndicator;

    _provincesToExclude: Array<number> = [];

    constructor( container: HTMLElement ) {
        this._container = container;
        this._currentLanguage = getLanguage();
        this._locationIndicator = new LocationIndicator();
        this._mapElement = this._container.querySelector( '.locationmap__map' ) as HTMLElement;

        if ( !this._mapElement ) {
            throw new Error( 'failed to find locaton map element' );
        }

        this._currentLocationID = parseInt( this._mapElement.dataset.id as string );

        this._init();
    }

    async _init(): Promise<void> {
        await loadMapboxGL();

        this._map = new window.mapboxgl.Map( {
            container: this._mapElement,
            style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=1gSrAVaVn53NYX4186Wo',
            attributionControl: false,
            center: [
                parseFloat( this._mapElement.dataset.centerLon || '0' ),
                parseFloat( this._mapElement.dataset.centerLat || '0' ),
            ],
            bounds: parseEscapedJSONString( this._mapElement.dataset.bbox || '[]' ),
            fitBoundsOptions: {
                padding: {
                    top: 40,
                    right: 20,
                    bottom: 40,
                    left: 20,
                },
                maxZoom: 10,
            },
        } );

        this._map.on( 'load', this._onMapInit.bind( this ) );
    }

    _onMapInit(): void {
        this._addLocationToMap();

        const iconElement = document.createElement( 'div' );
        iconElement.className = 'locationmap__marker';
        iconElement.innerHTML = `<img src="${window.assetsPrefix}/images/pin-red-base.svg" width="15" height="22" loading="lazy" alt="${this._mapElement.dataset.locationName}" />`;

        const mapMarker = new window.mapboxgl.Marker( {
            element: iconElement,
            anchor: 'bottom',
        } );

        mapMarker.setLngLat( [ parseFloat( this._mapElement.dataset.centerLon as string ), parseFloat( this._mapElement.dataset.centerLat as string ) ] );
        mapMarker.addTo( this._map );

        this._map.addControl( new window.mapboxgl.FullscreenControl( {
            container: this._container,
        } ), 'top-right' );

        document.addEventListener( 'fullscreenchange', this._onFullscreenChange.bind( this ) );

        this._map.addControl( new window.mapboxgl.NavigationControl( {
            showCompass: true,
        } ), 'top-right' );

        this._map.addControl( this._locationIndicator );

        if ( this._mapElement.dataset.markers && this._mapElement.dataset.markers.length > 0 ) {
            this._map.addControl( new PoiSelector( {
                container: this._container,
                groups: parseEscapedJSONString( this._mapElement.dataset.markers ) as Record<string, MarkerGroup>,
                labelText: this._mapElement.dataset.layerSelectLabel,
                placeholderText: this._mapElement.dataset.layerSelectPlaceholder,
            } ), 'top-left' );

            this._container.addEventListener( 'location-map:showing-poi', ( event: CustomEvent ) => {
                mapMarker.remove();
            } );

            this._container.addEventListener( 'location-map:hiding-poi', ( event: CustomEvent ) => {
                mapMarker.addTo( this._map );
            } );
        }

        this._map.addControl( new window.mapboxgl.AttributionControl( {
            compact: true,
        } ), 'top-right' );

        this._provincesToExclude = [
            parseInt( this._mapElement.dataset.parentProvince as string ),
            parseInt( this._mapElement.dataset.parentRegion as string ),
        ];

        this._map.on( 'mousemove', this._onMapMouseMove.bind( this ) );

        this._map.on( 'moveend', this._getViewportProvinces.bind( this ) );

        if ( isTouchDevice() ) {
            this._map.dragPan.disable();

            this._map.on( 'touchstart', ( event: mapboxgl.MapTouchEvent ) => {
                const e = event.originalEvent;

                if ( e && 'touches' in e ) {
                    if ( e.touches.length > 1 ) {
                        this._map.dragPan.enable();
                    } else {
                        this._map.dragPan.disable();
                    }
                }
            } );
        }
    }

    async _getViewportProvinces(): Promise<void> {
        const src = this._map.getSource( 'locations' ) as any;

        if ( !src ) {
            return;
        }

        const provinceIDs = [
            ...this._provincesToExclude,
            ...src.serialize().data.features.filter( ( feature: mapboxgl.MapboxGeoJSONFeature ) => {
                return feature.properties && feature.properties.type !== Municipality;
            } ).map( ( feature: mapboxgl.MapboxGeoJSONFeature ) => feature.id ),
        ];

        const url = new URL( `${window.geoAPI}/${Province}` );
        url.searchParams.set( 'excludeLocationIDs', provinceIDs.join( ',' ) );

        const currentFeatures = src.serialize().data.features || [];

        const bounds = this._map.getBounds();
        url.searchParams.set( 'boundSW', bounds.getSouthWest().lat + ',' + bounds.getSouthWest().lng );
        url.searchParams.set( 'boundNE', bounds.getNorthEast().lat + ',' + bounds.getNorthEast().lng );

        const response = await window.http( url );

        if ( response.status === 204 ) {
            return;
        }

        if ( response.status !== 200 ) {
            throw new Error( 'failed to get location geojson' );
        }

        const geojson = await response.json() as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

        src.setData( {
            type: 'FeatureCollection',
            features: [
                ...currentFeatures,
                ... geojson.features,
            ],
        } );
    }

    async _addLocationToMap(): Promise<void> {
        const url = new URL( `${window.geoAPI}/${Municipality}` );
        url.searchParams.set( 'parentType', Province );
        url.searchParams.set( 'parentID', this._mapElement.dataset.parentProvince as string );
        url.searchParams.set( 'skipBoundingBox', 'false' );

        const response = await window.http( url );

        if ( !response.ok ) {
            throw new Error( 'failed to get location geojson' );
        }

        const geojson = await response.json() as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

        this._map.addSource( 'locations', {
            type: 'geojson',
            data: geojson,
            tolerance: 0,
        } );

        await this._getViewportProvinces();

        this._map.addLayer( {
            id: 'locations:outline',
            source: 'locations',
            type: 'line',
            paint: {
                'line-color': '#4382CE',
                'line-width': [
                    'case',
                    [
                        'boolean',
                        [
                            'feature-state',
                            'hover',
                        ],
                        false,
                    ],
                    3.5,
                    1.5,
                ],
            },
        } );

        this._map.addLayer( {
            id: 'locations:fill',
            source: 'locations',
            type: 'fill',
            paint: {
                'fill-color': '#FFDE00',
                'fill-opacity': [
                    'case',
                    [
                        'boolean',
                        [
                            'feature-state',
                            'hover',
                        ],
                        false,
                    ],
                    0.4,
                    [
                        'boolean',
                        [
                            'feature-state',
                            'active',
                        ],
                        false,
                    ],
                    0.2,
                    0.0,
                ],
            },
        }, 'locations:outline' );

        this._map.setFeatureState( {
            source: 'locations',
            id: this._currentLocationID,
        },
        {
            active: true,
            hover: false,
        } );

        this._map.on( 'mousemove', this._onMapMouseMove.bind( this ) );

        this._map.on( 'click', this._onMapClick.bind( this ) );

        this._map.on( 'mouseout', this._onMapMouseLeave.bind( this ) );
    }

    _onMapMouseMove( event: mapboxgl.MapMouseEvent ): void {
        const features: Array<mapboxgl.MapboxGeoJSONFeature> = this._map.queryRenderedFeatures( event.point );

        const leave = () => {
            if ( this._currentCursorTarget === null ) {
                return;
            }

            this._map.setFeatureState( {
                source: 'locations',
                id: this._currentCursorTarget.id,
            },
            {
                hover: false,
            } );

            this._map.getCanvas().style.cursor = '';

            this._locationIndicator.clear();

            this._currentCursorTarget = null;
        };

        if ( !features || features.length === 0 ) {
            leave();
            return;
        }

        const topMostFeature = features[ 0 ];

        if ( topMostFeature.source !== 'locations' || !topMostFeature.id || topMostFeature.id === this._currentLocationID ) {
            leave();
            return;
        }

        if ( this._currentCursorTarget !== null && topMostFeature.id === ( this._currentCursorTarget.id as number ) ) {
            return;
        }

        if ( this._currentCursorTarget !== null ) {
            this._map.setFeatureState( {
                source: 'locations',
                id: this._currentCursorTarget.id,
            },
            {
                hover: false,
                active: false,
            } );
        }

        const feature = LocationFeature.fromMapBoxFeature( topMostFeature );

        this._currentCursorTarget = feature;

        this._map.setFeatureState( {
            source: 'locations',
            id: feature.id,
        },
        {
            hover: true,
            active: false,
        } );

        this._map.getCanvas().style.cursor = 'pointer';

        this._locationIndicator.update( feature.label( this._currentLanguage ) );
    }

    async _onMapClick( event: mapboxgl.MapMouseEvent ): Promise<void> {
        if ( this._currentCursorTarget === null ) {
            return;
        }

        const features: Array<mapboxgl.MapboxGeoJSONFeature> = this._map.queryRenderedFeatures( event.point );

        if ( !features || features.length === 0 ) {
            return;
        }

        const topMostFeature = features[ 0 ];

        if ( topMostFeature.source !== 'locations' || !topMostFeature.id ) {
            return;
        }

        if ( topMostFeature.properties && topMostFeature.properties.type !== Municipality ) {
            const url = new URL( `${window.geoAPI}/${Municipality}` );
            url.searchParams.set( 'parentType', topMostFeature.properties.type );
            url.searchParams.set( 'parentID', topMostFeature.id as string );
            url.searchParams.set( 'skipBoundingBox', 'false' );

            const response = await window.http( url );

            if ( !response.ok ) {
                throw new Error( 'failed to get location geojson' );
            }

            const geojson = await response.json() as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

            const src = this._map.getSource( 'locations' ) as any;

            const currentFeatures: Array<any> = src.serialize().data.features || [];

            src.setData( {
                type: 'FeatureCollection',
                features: [
                    ...currentFeatures.filter( ( f ) => f.properties.id !== topMostFeature.id ),
                    ... geojson.features,
                ],
            } );

            this._provincesToExclude.push( parseInt( topMostFeature.id as string ) );
            return;
        }

        const slugURL = new URL( window.location.origin + `/locations/${topMostFeature.id}/slug` );
        slugURL.searchParams.set( 'language', getLanguage() );

        const slugResponse = await window.http( slugURL );

        if ( slugResponse.status !== 200 ) {
            new Error( 'failed to get slug for location ' + topMostFeature.id );
        }

        const slugData: SlugResponse = await slugResponse.json();

        let path = '';

        if ( this._currentLanguage === 'en' ) {
            path = '/' + window.location.pathname.split( '/' )[ 1 ] + '/' + window.location.pathname.split( '/' )[ 2 ] + `/${slugData.Slug}`;
        } else {
            path = '/' + window.location.pathname.split( '/' )[ 1 ] + '/' + window.location.pathname.split( '/' )[ 2 ] + '/' + window.location.pathname.split( '/' )[ 3 ] + `/${slugData.Slug}`;
        }

        const newURL = new URL( window.location.origin + path );

        if ( document.querySelector( '.location-search-results' ) ) {
            this._mapElement.dispatchEvent( new CustomEvent( 'twc:manual-navigation', {
                bubbles: true,
                detail: {
                    url: newURL.toString(),
                    isRoutedPopup: true,
                },
            } ) );
        } else {
            navigateTo( newURL.toString() );
        }
    }

    _onMapMouseLeave( event: mapboxgl.MapMouseEvent ): void {
        if ( this._currentCursorTarget === null ) {
            return;
        }

        this._map.setFeatureState( {
            source: 'locations',
            id: this._currentCursorTarget.id,
        },
        {
            hover: false,
        } );

        this._map.getCanvas().style.cursor = '';

        this._locationIndicator.clear();

        this._currentCursorTarget = null;
    }

    _onFullscreenChange( event: Event ): void {
        this._container.classList.toggle( 'locationmap__container--fullscreened' );
        this._map.resize();
    }
}

export default LocationMap;
