import { parseEscapedJSONString } from '../json/parse';
import Logger from '../logging/logger';
import requestIdleCallback from '../shims/requestIdleCallback';
import { updateSavedStatus } from '../user/saved';
import { yieldToMainThread } from '../yield';
import { NewContentLoadedEvent } from '../events/new-content-loaded';

type PaginationResponse = {
    Response: string;
	Next: string;
	Prev: string;
	Curr: string;
	ForceRefine: boolean;
}

type ResultItemBreakpoint = {
    width: number;
    adIndex: number;
};

type BreakpointConfig = {
    adIndex: number;
}

function initPagination( configElement: HTMLElement ) {
    const loadNextBtn = document.querySelector<HTMLButtonElement | HTMLAnchorElement>( '.pagination-load-next' );
    const loadPreviousBtn = document.querySelector<HTMLButtonElement | HTMLAnchorElement>( '.pagination-load-prev' );
    const refineFiltersBtn = document.querySelector<HTMLButtonElement>( '.pagination-refine-filters' );

    const itemSelector = configElement.dataset.paginationResultItemSelector || '';

    if ( !itemSelector ) {
        throw new Error( 'no pagination result item selector specified' );
    }

    function getContainer(): HTMLElement {
        if ( !configElement.dataset.paginationResultContainerSelector ) {
            throw new Error( 'no pagination result container selector specified' );
        }

        const containerSelector = configElement.dataset.paginationResultContainerSelector;

        const resultContainers = document.querySelectorAll<HTMLElement>( containerSelector );

        if ( !resultContainers ) {
            throw new Error( 'no pagination result containers found' );
        }

        return resultContainers[ resultContainers.length - 1 ];
    }

    const resultItemBreakpoints: Array<ResultItemBreakpoint> = [];

    if ( configElement.dataset.paginationResultItemBreakpoints ) {
        const breakpoints: Record<string, BreakpointConfig> = parseEscapedJSONString<Record<string, BreakpointConfig>>( configElement.dataset.paginationResultItemBreakpoints );

        Object.keys( breakpoints ).forEach( ( key: string ) => {
            resultItemBreakpoints.push( {
                width: parseInt( key ),
                adIndex: breakpoints[ key ].adIndex,
            } );
        } );

        resultItemBreakpoints.sort( ( a: ResultItemBreakpoint, b: ResultItemBreakpoint ) => b.width - a.width );
    }

    let indexToInsertAdAfter: number = 0;

    if ( configElement.dataset.paginationIndexToInsertAdAfter ) {
        indexToInsertAdAfter = parseInt( configElement.dataset.paginationIndexToInsertAdAfter );
    }

    Logger.info( 'Initialising pagination', {
        resultItemBreakpoints,
        indexToInsertAdAfter,
    } );

    function startLoadingForEvent( event: Event ): void {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            return;
        }
        event.target.classList.add( 'btn--loading' );
    }

    function stopLoadingForEvent( event: Event ): void {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            return;
        }
        event.target.classList.remove( 'btn--loading' );
    }

    const loadFollowing = async function( event: Event ): Promise<void> {
        if ( !window.nextDataURL ) {
            return;
        }

        startLoadingForEvent( event );

        const isAnchorLink = event.target && event.target instanceof HTMLAnchorElement;

        if ( isAnchorLink ) {
            event.preventDefault();
        }

        const response = await window.http( new URL( window.location.origin + window.nextDataURL ) );

        const data = await response.json() as PaginationResponse;

        const gridContainer = getContainer();

        const range = document.createRange();
        range.selectNode( gridContainer );

        const fragment = range.createContextualFragment( data.Response );

        gridContainer.appendChild( fragment );

        stopLoadingForEvent( event );

        await yieldToMainThread();

        window.adManager.registerZones( document.body );

        window.nextDataURL = data.Next;

        if ( isAnchorLink ) {
            event.target.href = data.Next;
        }

        requestIdleCallback( () => {
            toggleNextPrevVisibility( data.ForceRefine );
        } );

        requestIdleCallback( () => {
            updateSavedStatus( gridContainer );
        } );

        document.dispatchEvent( new CustomEvent<NewContentLoadedEvent>( 'twc:newcontentloaded', {
            bubbles: true,
            detail: {
                container: document,
            },
        } ) );
    };

    const loadPrevious = async function( event: Event ): Promise<void> {
        if ( !window.prevDataURL ) {
            return;
        }

        startLoadingForEvent( event );

        const isAnchorLink = event.target && event.target instanceof HTMLAnchorElement;

        if ( isAnchorLink ) {
            event.preventDefault();
        }

        const response = await window.http( new URL( window.location.origin + window.prevDataURL ) );

        const data = await response.json();

        const gridContainer = getContainer();

        const range = document.createRange();
        range.selectNode( gridContainer );
        gridContainer.prepend( range.createContextualFragment( data.Response ) );

        stopLoadingForEvent( event );

        window.prevDataURL = data.Prev;

        if ( isAnchorLink ) {
            event.target.href = data.Prev;
        }

        window.adManager.registerZones( document.body );

        toggleNextPrevVisibility();

        requestIdleCallback( () => {
            updateSavedStatus( gridContainer );
        } );

        document.dispatchEvent( new CustomEvent<NewContentLoadedEvent>( 'twc:newcontentloaded', {
            bubbles: true,
            detail: {
                container: document,
            },
        } ) );
    };

    const toggleNextPrevVisibility = function( forceRefine: boolean = false ): void {
        if ( !window.nextDataURL || window.nextDataURL.length === 0 ) {
            if ( loadNextBtn ) {
                loadNextBtn.style.display = 'none';
            }

            if ( refineFiltersBtn ) {
                refineFiltersBtn.style.display = '';
            }
        } else {
            if ( loadNextBtn ) {
                loadNextBtn.style.display = '';
            }

            if ( refineFiltersBtn ) {
                refineFiltersBtn.style.display = 'none';
            }
        }

        if ( ( !window.prevDataURL || window.prevDataURL.length === 0 ) && loadPreviousBtn ) {
            loadPreviousBtn.style.display = 'none';
        }

        if ( ( window.prevDataURL && window.prevDataURL.length > 0 ) && loadPreviousBtn ) {
            loadPreviousBtn.style.display = '';
        }

        if ( forceRefine && refineFiltersBtn ) {
            refineFiltersBtn.style.display = '';
        }
    };

    let currentUrl = document.body.dataset.baseUrl;

    if ( !currentUrl ) {
        currentUrl = document.location.href;
    }

    toggleNextPrevVisibility();

    if ( loadNextBtn ) {
        loadNextBtn.addEventListener( 'click', loadFollowing );
    }

    if ( loadPreviousBtn ) {
        loadPreviousBtn.addEventListener( 'click', loadPrevious );
    }

    if ( refineFiltersBtn ) {
        refineFiltersBtn.addEventListener( 'click', function() {
            document.dispatchEvent( new CustomEvent( 'twc:popup:open-property-search-filters', {
                bubbles: true,
            } ) );
        } );
    }
}

export default initPagination;
