export type MarkerDefintion = {
    name: string;
    width: number;
    height: number;
}

const blueDots: Array<MarkerDefintion> = [
    {
        name: 'dot-blue-base',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-blue-base-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-blue-active',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-blue-active-saved',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-blue-active-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-blue-active-saved-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-blue-visited',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-blue-visited-saved',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-blue-visited-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-blue-visited-saved-hover',
        width: 25,
        height: 25,
    },
];

const redDots: Array<MarkerDefintion> = [
    {
        name: 'dot-red-base',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-red-base-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-red-base-saved',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-red-base-saved-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-red-active',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-red-active-saved',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-red-active-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-red-active-saved-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-red-visited',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-red-visited-saved',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-red-visited-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-red-visited-saved-hover',
        width: 25,
        height: 25,
    },

];

const greyDots: Array<MarkerDefintion> = [
    {
        name: 'dot-grey-discarded',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-grey-discarded-hover',
        width: 25,
        height: 25,
    },
];

const redPins: Array<MarkerDefintion> = [
    {
        name: 'pin-red-base',
        width: 20,
        height: 30,
    },
    {
        name: 'pin-red-base-hover',
        width: 25,
        height: 37.5,
    },
    {
        name: 'pin-red-visited',
        width: 20,
        height: 30,
    },
    {
        name: 'pin-red-visited-saved',
        width: 20,
        height: 30,
    },
    {
        name: 'pin-red-visited-hover',
        width: 25,
        height: 37.5,
    },
    {
        name: 'pin-red-visited-saved-hover',
        width: 25,
        height: 37.5,
    },
    {
        name: 'pin-red-active',
        width: 20,
        height: 30,
    },
    {
        name: 'pin-red-active-saved',
        width: 20,
        height: 30,
    },
    {
        name: 'pin-red-active-contacted',
        width: 20,
        height: 30,
    },
    {
        name: 'pin-red-active-hover',
        width: 25,
        height: 37.5,
    },
    {
        name: 'pin-red-active-saved-hover',
        width: 25,
        height: 37.5,
    },
    {
        name: 'pin-red-active-contacted-hover',
        width: 25,
        height: 37.5,
    },
];

const greenPins: Array<MarkerDefintion> = [
    {
        name: 'pin-green-visited-contacted',
        width: 20,
        height: 30,
    },
    {
        name: 'pin-green-visited-contacted-hover',
        width: 25,
        height: 37.5,
    },
    {
        name: 'pin-green-active-contacted',
        width: 20,
        height: 30,
    },
    {
        name: 'pin-green-active-contacted-hover',
        width: 25,
        height: 37.5,
    },
];

const greyPins: Array<MarkerDefintion> = [
    {
        name: 'pin-grey-discarded',
        width: 20,
        height: 30,
    },
    {
        name: 'pin-grey-discarded-hover',
        width: 25,
        height: 37.5,
    },
];

const greenDots: Array<MarkerDefintion> = [
    {
        name: 'dot-green-visited-contacted',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-green-visited-contacted-hover',
        width: 25,
        height: 25,
    },
    {
        name: 'dot-green-active-contacted',
        width: 20,
        height: 20,
    },
    {
        name: 'dot-green-active-contacted-hover',
        width: 25,
        height: 25,
    },
];

export default [
    ...redDots,
    ...redPins,
    ...blueDots,
    ...greenDots,
    ...greenPins,
    ...greyDots,
    ...greyPins,
] as Array<MarkerDefintion>;
