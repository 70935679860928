export default function initListExpanders( container = document ): void {
    container.querySelectorAll( '.listexpander' ).forEach( ( btn: HTMLButtonElement ): void => {
        if ( btn.classList.contains( 'listexpander--initialised' ) ) {
            return;
        }

        btn.addEventListener( 'click', ( event: Event ): void => {
            const parent = btn.closest( btn.dataset.parent || 'ul' );

            if ( !parent ) {
                return;
            }

            const siblingSelector = btn.dataset.sibling || 'li';

            let limit = 2;

            if ( btn.dataset.limit && btn.dataset.limit.length > 0 ) {
                limit = parseInt( btn.dataset.limit );
            }

            if ( btn.classList.contains( 'listexpander--open' ) ) {
                parent.querySelectorAll( siblingSelector ).forEach( ( li, index ) => {
                    if ( index < limit || li.querySelector( '.listexpander' ) !== null ) {
                        return;
                    }
                    li.classList.add( 'd-none' );
                } );
                btn.classList.remove( 'listexpander--open' );
            } else {
                parent.querySelectorAll( siblingSelector ).forEach( ( li ) => li.classList.remove( 'd-none' ) );
                btn.classList.add( 'listexpander--open' );
            }
        } );

        btn.classList.add( 'listexpander--initialised' );
    } );
}
