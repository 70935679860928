import mapMarkers, { MarkerDefintion } from './index';
import { assetURL } from '../../url';
import mapboxgl from 'mapbox-gl';

function addMarkersToMap( ctx: mapboxgl.Map ): Promise<void> {
    return new Promise( function( resolve, reject ) {
        let loadCount = 0;

        mapMarkers.forEach( function( marker: MarkerDefintion ) {
            const icon = new Image( marker.width, marker.height );
            icon.crossOrigin = 'Anonymous';

            icon.onerror = function() {
                reject( new Error( `failed loading marker: ${marker.name}` ) );
            };

            icon.onload = function() {
                if ( ctx.hasImage( marker.name ) ) {
                    ctx.removeImage( marker.name );
                }

                ctx.addImage( marker.name, icon );

                loadCount++;

                if ( loadCount === mapMarkers.length ) {
                    resolve();
                }
            };

            icon.src = assetURL( `/images/${marker.name}.svg` ).toString();
        } );
    } );
}

function addMarkerToMap( ctx: mapboxgl.Map, markerName: string ): Promise<void> {
    return new Promise( function( resolve, reject ) {
        const matchingMarker = mapMarkers.find( ( m: MarkerDefintion ) => m.name === markerName );

        if ( !matchingMarker ) {
            reject( new Error( `no matching markers for id: ${markerName}` ) );
            return;
        }

        const marker = matchingMarker;

        const icon = new Image( marker.width, marker.height );
        icon.crossOrigin = 'Anonymous';

        icon.onerror = function() {
            reject( new Error( `failed loading marker: ${marker.name}` ) );
        };

        icon.onload = function() {
            try {
                if ( ctx.hasImage( markerName ) ) {
                    reject( new Error( `cannot add duplicate marker with id: ${markerName}` ) );
                }

                ctx.addImage( marker.name, icon );
            } catch ( err ) {
                reject( err );
            }
            resolve();
        };

        icon.src = assetURL( `/images/${marker.name}.svg` ).toString();
    } );
}

export {
    addMarkersToMap,
    addMarkerToMap,
};
