import { validateBoundingBox } from '../bounding-box.js';
import { locationTypes } from '../../locations/index.js';
import { Coordinate } from './coordinate.js';

import {
    Coast,
    District,
    Entity,
    Island,
    Municipality,
    Neighbourhood,
    Province,
    Region,
} from '../../locations/index.js';

interface ChildLocationType {
    total: number;
    type: string;
}

/**
 * LocationFeature represents a GeoJSON feature
 * Computed values are available:
 * - position - the locations center point as an object
 * - bbox - the locations bounding box as an array
 * - bboxSouthWest - the bbox SW coordinate pair as a string
 * - bboxNorthEast - the bbox NE coordinate pair as a string
 * - idKey - the locations type/id pair as a string
 */
class LocationFeature {
    id = 0;
    type: string;
    labels: Record<string, string> = {};
    childLocationTypes: Array<ChildLocationType> = [];
    searchSynonymousForLocationType: string;
    locationTypeSkipped: string;
    centerLatitude = 0;
    centerLongitude = 0;
    area = 0;
    boundingBoxMinLng = 0;
    boundingBoxMinLat = 0;
    boundingBoxMaxLng = 0;
    boundingBoxMaxLat = 0;
    regionID = 0;
    provinceID = 0;
    islandID = 0;
    coastID: Array<number> = [];
    municipalityID: Array<number> = [];
    districtID: Array<number> = [];
    neighbourhoodID: Array<number> = [];
    entityID: Array<number> = [];
    parentLocations: Array<LocationFeature> = [];

    get position(): Coordinate {
        return {
            lat: this.centerLatitude,
            lng: this.centerLongitude,
        };
    }

    get bbox(): mapboxgl.LngLatBoundsLike {
        return [
            [
                this.boundingBoxMinLng, this.boundingBoxMinLat,
            ],
            [
                this.boundingBoxMaxLng, this.boundingBoxMaxLat,
            ],
        ];
    }

    get bboxSouthWest(): string {
        return `${this.boundingBoxMaxLat},${this.boundingBoxMaxLng}`;
    }

    get bboxNorthEast(): string {
        return `${this.boundingBoxMinLat},${this.boundingBoxMinLng}`;
    }

    get idKey(): string {
        return `${this.type}:${this.id}`;
    }

    get isIslandLocation(): boolean {
        return this.type !== Island && this.islandID > 0;
    }

    get hasBbox(): boolean {
        return (
            this.boundingBoxMaxLat !== 0 &&
            this.boundingBoxMaxLng !== 0 &&
            this.boundingBoxMinLat !== 0 &&
            this.boundingBoxMinLng !== 0
        );
    }

    get hasChildren(): boolean {
        return this.childLocationTypes.length > 0;
    }

    countOfChildType( type: string ): number {
        const childOfType = this.childLocationTypes.find( ( f ) => f.type === type );

        if ( !childOfType ) {
            return 0;
        }

        return childOfType.total;
    }

    nextChildFeatureType() {
        if ( !this.childLocationTypes ) {
            return null;
        }

        if ( this.type === Region ) {
            const provinceCount = this.countOfChildType( Province );
            const hasIslands = this.childLocationTypes.some( ( t ) => t.type === Island );

            if ( hasIslands ) {
                return Island;
            }

            if ( provinceCount === 1 ) {
                return Municipality;
            }

            return Province;
        }

        if ( this.type === Province ) {
            const hasIslands = this.childLocationTypes.some( ( t ) => t.type === Island );

            if ( hasIslands ) {
                return Island;
            }

            return Municipality;
        }

        if ( this.type === Island ) {
            return Municipality;
        }

        if ( this.type === Coast ) {
            return Municipality;
        }

        const hasDistricts = this.childLocationTypes.find( ( f ) => {
            return f.type === District;
        } );

        if ( hasDistricts ) {
            return District;
        }

        const hasNeighbourhoods = this.childLocationTypes.find( ( f ) => {
            return f.type === Neighbourhood;
        } );

        if ( hasNeighbourhoods ) {
            return Neighbourhood;
        }

        return Entity;
    }

    label( language: string ): string {
        return this.labels[ language ] || this.labels[ 'es' ];
    }

    /**
     * fromMapBoxFeature creates a new LocationFeature from an existing mapbox type feature
     * @param {mapbox.Feature} feature - the feature object from mapbox, e.g. from map.queryRenderedFeatures
     * @returns LocationFeature
     */
    static fromMapBoxFeature( feature: any ): LocationFeature {
        const locationFeature = new LocationFeature();

        locationFeature.id = parseInt( feature.id );
        locationFeature.type = feature.properties.type;

        if ( typeof feature.properties.labels === 'string' ) {
            locationFeature.labels = JSON.parse( feature.properties.labels );
        } else {
            locationFeature.labels = feature.properties.labels;
        }

        if ( feature.properties.childLocationTypes ) {
            locationFeature.childLocationTypes = JSON.parse( feature.properties.childLocationTypes );
        }

        locationFeature.searchSynonymousForLocationType = feature.properties.searchSynonymousForLocationType;
        locationFeature.centerLatitude = parseFloat( feature.properties.centerLat );
        locationFeature.centerLongitude = parseFloat( feature.properties.centerLng );
        locationFeature.area = parseFloat( feature.properties.area );
        locationFeature.boundingBoxMinLng = parseFloat( feature.properties.boundingBoxMinLng );
        locationFeature.boundingBoxMinLat = parseFloat( feature.properties.boundingBoxMinLat );
        locationFeature.boundingBoxMaxLng = parseFloat( feature.properties.boundingBoxMaxLng );
        locationFeature.boundingBoxMaxLat = parseFloat( feature.properties.boundingBoxMaxLat );
        locationFeature.regionID = parseInt( feature.properties.regionID );
        locationFeature.provinceID = parseInt( feature.properties.provinceID );
        locationFeature.islandID = parseInt( feature.properties.islandID );

        if ( Array.isArray( feature.properties.coastID ) ) {
            locationFeature.coastID = feature.properties.coastID;
        } else if ( typeof feature.properties.coastID === 'number' ) {
            locationFeature.coastID = [ feature.properties.coastID ];
        } else {
            locationFeature.coastID = JSON.parse( feature.properties.coastID || '[]' );
        }

        if ( Array.isArray( feature.properties.municipalityID ) ) {
            locationFeature.municipalityID = feature.properties.municipalityID;
        } else {
            locationFeature.municipalityID = JSON.parse( feature.properties.municipalityID || '[]' );
        }

        if ( Array.isArray( feature.properties.districtID ) ) {
            locationFeature.districtID = feature.properties.districtID;
        } else {
            locationFeature.districtID = JSON.parse( feature.properties.districtID || '[]' );
        }

        if ( Array.isArray( feature.properties.neighbourhoodID ) ) {
            locationFeature.neighbourhoodID = feature.properties.neighbourhoodID;
        } else {
            locationFeature.neighbourhoodID = JSON.parse( feature.properties.neighbourhoodID || '[]' );
        }

        if ( Array.isArray( feature.properties.entityID ) ) {
            locationFeature.entityID = feature.properties.entityID;
        } else {
            locationFeature.entityID = JSON.parse( feature.properties.entityID || '[]' );
        }

        const err = validateBoundingBox( locationFeature.bbox as Array<Array<number>> );

        if ( err ) {
            throw err;
        }

        if ( !locationTypes.includes( locationFeature.type ) ) {
            throw new Error( `unknown location type "${locationFeature.type}"` );
        }

        return locationFeature;
    }

    static fromJSON( json: string ): LocationFeature {
        const deserialized = JSON.parse( json );
        const locationFeature = new LocationFeature();

        locationFeature.id = parseInt( deserialized.id );
        locationFeature.type = deserialized.type;

        if ( deserialized.properties ) {
            locationFeature.locationTypeSkipped = deserialized.properties.locationTypeSkipped;
        }

        if ( deserialized.childLocationTypes && deserialized.childLocationTypes.length > 0 ) {
            locationFeature.childLocationTypes = deserialized.childLocationTypes;
        }

        locationFeature.boundingBoxMinLng = parseFloat( deserialized.bbox.northEast.lon );
        locationFeature.boundingBoxMinLat = parseFloat( deserialized.bbox.northEast.lat );
        locationFeature.boundingBoxMaxLng = parseFloat( deserialized.bbox.southWest.lon );
        locationFeature.boundingBoxMaxLat = parseFloat( deserialized.bbox.southWest.lat );

        if ( deserialized.parentLocations && deserialized.parentLocations.length > 0 ) {
            for ( let parent = 0; parent < deserialized.parentLocations.length; parent++ ) {
                locationFeature.parentLocations.push( LocationFeature.fromJSON( JSON.stringify( deserialized.parentLocations[ parent ] ) ) );
            }
        }

        locationFeature.labels = deserialized.labels;

        return locationFeature;
    }
}

export default LocationFeature;
