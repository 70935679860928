import { LayerOption, MarkerGroup } from '../models';
import PoiSelector from './poi-selector';

class PoiGeoJsonLayerOption implements LayerOption {
    id: string;
    active: boolean;
    _map: mapboxgl.Map;
    _group: MarkerGroup;
    _option: HTMLOptionElement;
    _container: PoiSelector;
    _data: any = null;

    constructor( container: PoiSelector, group: MarkerGroup ) {
        this.id = group.type;
        this._container = container;
        this._group = group;
        this._option = document.createElement( 'option' );
        this._option.className = 'locationmap__layeroption';
        this._option.value = this.id;
        this._option.innerHTML = this._group.text;
    }

    onAdd( map: mapboxgl.Map ): HTMLOptionElement {
        this._map = map;

        this._map.on( 'click', `${this.id}:outline`, this._onClick.bind( this ) );

        this._map.on( 'mousemove', this._onMapMouseMove.bind( this ) );

        return this._option;
    }

    onRemove( map: mapboxgl.Map ): void {
        this._option.remove();
    }

    _onMapMouseMove( event: mapboxgl.MapMouseEvent ): void {
        const features = this._map.queryRenderedFeatures( event.point );

        if ( !features || features.length === 0 ) {
            return;
        }

        const feature = features[ 0 ];

        if ( feature.source !== this.id ) {
            return;
        }

        this._map.getCanvas().style.cursor = 'pointer';
    }

    _onClick( event: mapboxgl.MapMouseEvent ): void {
        const features = this._map.queryRenderedFeatures( event.point );

        if ( !features || features.length === 0 ) {
            return;
        }

        const feature = features[ 0 ];

        if ( feature.source !== this.id || !feature.properties ) {
            return;
        }

        const popupHTML = decodeURIComponent( JSON.parse( '"' + `${feature.properties.name} (${feature.properties.code})` + '"' ) );

        new window.mapboxgl.Popup()
            .setLngLat( [ event.lngLat.lng, event.lngLat.lat ] )
            .setHTML( `<a class="locationmarkerlink" href="#${this.id}-${feature.id}">${popupHTML}</a>` )
            .addTo( this._map );
    }

    async toggle(): Promise<void> {
        if ( !this.active ) {
            await this.activate();
        } else {
            this.deactivate();
        }
    }

    async activate(): Promise<void> {
        this._container.deactivateAll();

        if ( this._data === null ) {
            const url = new URL( window.geoAPI + this._group.geoJsonURL );

            const response: Response = await window.http( url.toString() );

            if ( response.status === 204 ) {
                return;
            }

            if ( !response.ok ) {
                throw new Error( 'failed getting route intersections' );
            }

            this._data = await response.json();
        }

        if ( !this._map.getSource( this.id ) ) {
            this._map.addSource( this.id, {
                type: 'geojson',
                data: this._data,
                tolerance: 0,
            } );
        }

        if ( !this._map.getLayer( `${this.id}:outline` ) ) {
            this._map.addLayer( {
                id: `${this.id}:outline`,
                source: this.id,
                type: 'line',
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'line-color': '#d1041d',
                    'line-width': [
                        'interpolate',
                        [ 'exponential', 2 ],
                        [ 'zoom' ],
                        0,
                        2,
                        12,
                        4,
                        20,
                        6,
                    ],
                },
            } );
        }

        this._map.setLayoutProperty( `${this.id}:outline`, 'visibility', 'visible' );

        this.active = true;
    }

    deactivate(): void {
        if ( this._map.getLayer( `${this.id}:outline` ) ) {
            this._map.setLayoutProperty( `${this.id}:outline`, 'visibility', 'none' );
        }
        this.active = false;
    }
}

export default PoiGeoJsonLayerOption;
