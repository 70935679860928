import { NewContentLoadedEvent } from '../events/new-content-loaded';

class ImagePopup {
    _image: HTMLImageElement;
    _caption = '';
    _container: HTMLElement;

    constructor( image: HTMLImageElement ) {
        let container = document.querySelector<HTMLElement>( '#image-popup-container' );

        if ( !container ) {
            container = document.createElement( 'div' );
            container.id = 'image-popup-container';
            document.body.appendChild( container );
        }

        this._image = image;
        this._container = container;

        if ( this._image.parentElement && this._image.parentElement.matches( 'figure' ) ) {
            const caption = this._image.parentElement.querySelector( 'figcaption' );

            if ( caption ) {
                this._caption = caption.innerText;
            }
        }

        this._image.addEventListener( 'click', this.onClick.bind( this ) );
        this._image.classList.add( 'img-popup-initialised' );
    }

    onClick( event: Event ): void {
        event.stopImmediatePropagation();

        const popupImg = document.createElement( 'img' );
        popupImg.src = this._image.src;

        this._container.innerHTML = '';

        const figure = document.createElement( 'figure' );
        figure.appendChild( popupImg );

        if ( this._caption.length > 0 ) {
            const figcaption = document.createElement( 'figcaption' );
            figcaption.innerText = this._caption;
            figure.appendChild( figcaption );
        }

        this._container.classList.add( 'open' );
        this._container.appendChild( figure );

        document.addEventListener( 'click', this.onClose.bind( this ), {
            once: true,
        } );
    }

    onClose( event: Event ): void {
        this._container.classList.remove( 'open' );
    }
}

export default function initImagePopups() {
    const init = () => {
        const elements = document.querySelectorAll<HTMLImageElement>( '.img-popup' );

        elements.forEach( ( img ) => {
            if ( img.classList.contains( 'img-popup-initialised' ) ) {
                return;
            }
            new ImagePopup( img );
        } );
    };

    document.addEventListener( 'twc:newcontentloaded', ( event: CustomEvent<NewContentLoadedEvent> ) => init() );

    init();
}
