const Country = 'country';
const Region = 'region';
const Province = 'province';
const Coast = 'coast';
const Island = 'island';
const Municipality = 'municipality';
const District = 'district';
const Neighbourhood = 'neighbourhood';
const Entity = 'entity';

const locationTypes = [
    Country,
    Region,
    Province,
    Coast,
    Island,
    Municipality,
    District,
    Neighbourhood,
    Entity,
];

function parseLocationKey( str, useProvidedType = false ) {
    let key = {
        type: '',
        id: 0,
    };

    if ( typeof str !== 'string' ) {
        throw new Error( 'Cannot parse location key of type ' + typeof str + ', string required' );
    }

    const parts = str.split( ':' );

    if ( parts.length !== 2 ) {
        throw new Error( 'Invalid location key "' + str + '", format: "{type}:{id}"' );
    }

    if ( useProvidedType ) {
        key.type = parts[ 0 ] || '';
    } else {
        key.type = getGeoAPIPathForLocationType( parts[ 0 ] ) || '';
    }

    key.id = parseInt( parts[ 1 ] ) || 0;

    return key;
}

function getGeoAPIPathForLocationType( locationType ) {
    let path;

    switch ( locationType ) {
    case 'town':
        path = Municipality;
        break;
    default:
        path = locationType;
        break;
    }

    return path;
}

function getParentLocationTypeForChild( childType ) {
    let type;

    switch ( childType ) {
    case Entity:
        type = Municipality;
        break;

    case Neighbourhood:
        type = District;
        break;

    case District:
        type = Municipality;
        break;

    case 'town':
        type = Province;
        break;

    case Municipality:
        type = Province;
        break;

    case Province:
        type = Region;
        break;

    case Island:
        type = Province;
        break;

    case Coast:
        type = Province;
        break;

    case Region:
        type = Region;
        break;
    }

    return type;
}

function getChildLocationTypeForParent( parentType ) {
    let type;

    switch ( parentType ) {
    case Entity:
        type = Entity;
        break;

    case Neighbourhood:
        type = Entity;
        break;

    case District:
        type = Entity;
        break;

    case 'town':
        type = Entity;
        break;

    case Municipality:
        type = Entity;
        break;

    case Province:
        type = Municipality;
        break;

    case Island:
        type = Municipality;
        break;

    case Coast:
        type = Municipality;
        break;

    case Region:
        type = Province;
        break;
    }

    return type;
}

function getAllChildLocationTypesForParent( parentType ) {
    let types = [];

    switch ( parentType ) {
    case Municipality:
        types.push(
            Entity,
            Neighbourhood,
            District,
        );
        break;
    case Province:
        types.push( Province );
    }

    return types;
}

function getNextChildLocationType( feature ) {
    let expectedChildType = getChildLocationTypeForParent( feature.properties.type );

    if ( !feature.properties.childLocationTypes ) {
        return expectedChildType;
    }

    let availableTypes = JSON.parse( feature.properties.childLocationTypes || '[]' );

    let hasIslands = availableTypes.some( ( t ) => {
        return t.type === Island;
    } );

    let provinces = availableTypes.find( ( t ) => {
        return t.type === Province;
    } );

    if ( ( hasIslands && provinces && provinces.total === 1 ) || ( hasIslands && feature.properties.type === Province ) ) {
        expectedChildType = Island;
    }

    let expectedChild = availableTypes.find( ( t ) => {
        return t.type === expectedChildType;
    } );

    if ( hasIslands && expectedChild.total > 0 ) {
        return expectedChildType;
    }

    if ( !hasIslands && expectedChild.total > 1 ) {
        return expectedChildType;
    }

    let validChild = false;

    while ( !validChild ) {
        let nextExpectedChildType = getChildLocationTypeForParent( expectedChildType );

        let nextExpectedChild = availableTypes.find( ( t ) => {
            return t.type === nextExpectedChildType;
        } );

        if ( !nextExpectedChild ) {
            break;
        }

        if ( nextExpectedChild.total > 1 ) {
            validChild = true;
            expectedChildType = nextExpectedChildType;
        }
    }

    return expectedChildType;
}

function locationShouldLoadProperties( type ) {
    return [ Municipality, 'town', Entity ].includes( type );
}

export {
    Country,
    Region,
    Province,
    Coast,
    Island,
    Municipality,
    District,
    Neighbourhood,
    Entity,
    locationTypes,
    parseLocationKey,
    getGeoAPIPathForLocationType,
    getChildLocationTypeForParent,
    locationShouldLoadProperties,
    getNextChildLocationType,
    getParentLocationTypeForChild,
    getAllChildLocationTypesForParent,
};
