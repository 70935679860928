import { MapboxMaxZoom } from './zoom-levels.js';
import { truncateToFixed } from './spatial.js';

/**
 * @param {Array} boundingBox - an array-like bounding box
 * @example
 * let boundingBox = [
 *  [ longitude, latitude ],
 *  [ longitude, latitude ]
 * ];
 * let valid = validateBoundingBox( boundingBox );
 */
function validateBoundingBox( boundingBox ) {
    if ( !Array.isArray( boundingBox ) ) {
        return new Error( 'Invalid bounding box, not an Array' );
    }

    if ( boundingBox.length !== 2 ) {
        return new Error( 'Invalid bounding box, length != 2' );
    }

    for ( let i = 0; i < boundingBox.length; i++ ) {
        if ( boundingBox[ i ].length !== 2 ) {
            return new Error( 'Invalid coordinate, length != 2' );
        }

        for ( let j = 0; j < boundingBox[ i ].length; j++ ) {
            const indexStr = `[${i}][${j}]`;

            const number = Number( boundingBox[ i ][ j ] );

            if ( Number.isNaN( number ) ) {
                return new Error( 'Invalid coordinate value: ' + boundingBox[ i ][ j ] + ' ' + indexStr );
            }

            if ( j % 2 === 0 && ( number < -90 || number > 90 ) ) {
                return new Error( `Invalid latitude, valid range is -90 to 90 ${indexStr}` );
            }

            if ( j % 3 === 0 && ( number < -180 || number > 180 ) ) {
                return new Error( `Invalid longitude, valid range is -180 to 180 ${indexStr}` );
            }
        }
    }

    return null;
}

/**
 * boundingBoxIsLikePoint determines whether the min/max values are equal
 * @param {Array} boundingBox - an array-like bounding box
 */
function boundingBoxIsLikePoint( boundingBox ) {
    const maxLon = boundingBox[ 0 ][ 0 ];
    const maxLat = boundingBox[ 0 ][ 1 ];
    const minLon = boundingBox[ 1 ][ 0 ];
    const minLat = boundingBox[ 1 ][ 1 ];

    return ( ( maxLat === minLat ) && ( maxLon === minLon ) );
}

/**
 * @param {Array} coordinates - an array of longitude/latitude pairs ( [ {lon}, {lat} ]) extract the bounding box from
 * @example
 * let coords = [
 *  [ 180, 90 ],
 *  [ -180, 90 ]
 * ];
 * let bbox = getBoundingBoxFromFlatCoords( coords );
 */
function getBoundingBoxFromFlatCoords( coordinates ) {
    const highToLow = ( a, b ) => {
        return b - a;
    };

    const longitudes = coordinates.map( ( coord ) => {
        return coord[ 0 ];
    } ).sort( highToLow );

    const latitudes = coordinates.map( ( coord ) => {
        return coord[ 1 ];
    } ).sort( highToLow );

    return [
        [
            longitudes[ longitudes.length - 1 ],
            latitudes[ latitudes.length - 1 ],
        ],
        [
            longitudes[ 0 ],
            latitudes[ 0 ],
        ],
    ];
}

function newBoundingBoxEvent( additionalProperties = {} ) {
    return Object.assign( {}, {
        boxAnimation: true,
        isRadiusCircle: false,
        isAreaRectangle: false,
    }, additionalProperties );
}

function newBoundingBoxOptions() {
    return {
        maxZoom: MapboxMaxZoom, // fitBounds throws an error if this is not a number, e.g. undefined
        duration: 400,
    };
}

/**
 *
 * @param {MapSearch} ctx - the MapSearch context
 * @param {*} boundsToFit - the bounding box options
 * @returns void
 */
async function fitBoundsAsync( ctx, boundsToFit ) {
    return new Promise( ( resolve ) => {
        const timeout = window.setTimeout( () => {
            resolve();
        }, 3500 );

        ctx.on( 'twc:mapsearch:bboxend', () => {
            window.clearTimeout( timeout );
            resolve();
        }, {
            once: true,
        } );

        ctx.map.fitBounds( boundsToFit.bbox, boundsToFit.options, boundsToFit.event );
    } );
}

/**
 * @param {Array} bounds - a bounding box as an array in the format, usually from map.getBounds().toArray()
 * @returns string
 * @example
 * boundsAsPolygonCSV( [
 *     [ -1.70136, 37.84382 ], // south west lon, lat
 *     [ 0.841451, 38.88739 ], // north east lon, lat
 * ] );
 */
function boundsAsPolygonCSV( bounds ) {
    return [
        bounds[ 0 ][ 0 ], // sw
        bounds[ 0 ][ 1 ],
        bounds[ 1 ][ 0 ], // se
        bounds[ 0 ][ 1 ],
        bounds[ 1 ][ 0 ], // ne
        bounds[ 1 ][ 1 ],
        bounds[ 0 ][ 0 ], // nw
        bounds[ 1 ][ 1 ],
        bounds[ 0 ][ 0 ], // sw
        bounds[ 0 ][ 1 ],
    ].map( ( n ) => truncateToFixed( n ) ).toString();
}

export {
    validateBoundingBox,
    boundingBoxIsLikePoint,
    getBoundingBoxFromFlatCoords,
    newBoundingBoxEvent,
    fitBoundsAsync,
    newBoundingBoxOptions,
    boundsAsPolygonCSV,
};
