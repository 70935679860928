/**
 * @param {String} src - the URL/relative of the script to load
 * @param {Function} done - a callback function to be executed once the script has loaded
 */
function loadScript( src: string, done: ( err?: Error ) => void ): void {
    const js = document.createElement( 'script' );
    js.src = src;

    js.onload = function() {
        if ( done ) {
            done();
        }
    };

    js.onerror = function() {
        done( new Error( 'Failed to load script ' + src ) );
    };

    document.head.appendChild( js );
}

export {
    loadScript,
};
