import requestIdleCallback from '../shims/requestIdleCallback';

class ReadMore {
    constructor( el, options ) {
        this.root = el;
        this.options = Object.assign( {}, ReadMore.defaultOptions, options );

        if ( this.root.textContent.length < this.options.textLimit ) {
            return;
        }

        if ( this.root.dataset.tsReadmoreDone && this.root.dataset.tsReadmoreDone === 'true' ) {
            return;
        }

        this.root.classList.add( this.options.shortenClass );

        this.btn = document.createElement( 'button' );
        this.btn.className = 'readmore-link bold d-inline-flex flex-align-center';
        this.btn.innerHTML = `<svg viewbox="0 0 10 10" width="10" height="10" class="v-fill v-fill-blue mr-xs"><use xlink:href="#icon-plus" /></svg><strong class="readmore-txt">${this.options.readMoreText}</strong>`;
        this.btn.addEventListener( 'click', this.onClick.bind( this ) );

        this.root.parentNode.appendChild( this.btn );

        this.root.dataset.tsReadmoreDone = true;
    }

    onClick() {
        this.root.classList.remove( this.options.shortenClass );

        requestIdleCallback( () => {
            this.btn.remove();
        } );

        if ( this.options.onClicked ) {
            this.options.onClicked();
        }
    }
}

ReadMore.defaultOptions = {
    // These are the defaults.
    textLimit: 600,
    // text limit from where we want to hide
    renderAttr: 'ts-readmore-done',
    // attribute to control read more button has been added
    shortenClass: 'readmore-shorten',
    // class to short text by css
    readMoreText: 'Read More',
    // readmore text to put on button
    onClicked: null,
};

export default ReadMore;
