import { LayerOption, Marker, MarkerGroup } from '../models';
import PoiSelector from './poi-selector';

class PoiMarkerLayerOption implements LayerOption {
    id: string;
    active: boolean;
    _map: mapboxgl.Map;
    _group: MarkerGroup;
    _option: HTMLOptionElement;
    _markers: Array<mapboxgl.Marker> = [];
    _container: PoiSelector;

    constructor( container: PoiSelector, group: MarkerGroup ) {
        this.id = group.type;
        this._container = container;
        this._group = group;
        this._option = document.createElement( 'option' );
        this._option.className = 'locationmap__layeroption';
        this._option.value = this.id;
        this._option.innerHTML = this._group.text;

        if ( this._group.markers && this._group.markers.length > 0 ) {
            this._group.markers.forEach( ( marker: Marker ) => {
                let html = '';

                if ( marker.html && marker.html.length > 0 ) {
                    html = marker.html;
                }

                const iconElement = document.createElement( 'div' );
                iconElement.className = 'locationmap__marker';
                iconElement.innerHTML = `<img src="${window.assetsPrefix}/images/pin-red-base.svg" width="15" height="22" loading="lazy" alt="${html}" />`;

                const mapMarker = new window.mapboxgl.Marker( {
                    element: iconElement,
                    anchor: 'bottom',
                } );

                if ( html && html.length > 0 ) {
                    if ( marker.href && marker.href.length > 0 ) {
                        const markerHTML = decodeURIComponent( JSON.parse( '"' + marker.html + '"' ) );
                        html = `<a class="locationmarkerlink" href="${marker.href}">${markerHTML}</a>`;
                    }

                    let popup = new window.mapboxgl.Popup( {
                        anchor: 'bottom',
                        offset: [ 0, -20 ],
                    } );

                    popup = popup.setHTML( html );

                    mapMarker.setPopup( popup );
                }

                mapMarker.setLngLat( [ marker.lon, marker.lat ] );

                this._markers = [
                    ...this._markers,
                    mapMarker,
                ];
            } );
        }
    }

    onAdd( map: mapboxgl.Map ): HTMLOptionElement {
        this._map = map;
        return this._option;
    }

    onRemove( map: mapboxgl.Map ): void {
        this._option.remove();
    }

    toggle(): void {
        if ( !this.active ) {
            this.activate();
        } else {
            this.deactivate();
        }
    }

    activate(): void {
        this._container.deactivateAll();

        this.active = true;

        if ( this._group.markers && this._group.markers.length > 0 ) {
            this._markers.forEach( ( marker: mapboxgl.Marker ) => marker.remove() );

            this._markers.forEach( ( marker: mapboxgl.Marker ) => marker.addTo( this._map ) );

            if ( this._markers.length === 1 ) {
                this._map.setCenter( this._markers[ 0 ].getLngLat() );
            }
        }
    }

    deactivate(): void {
        this.active = false;

        if ( this._group.markers && this._group.markers.length > 0 ) {
            this._markers.forEach( ( marker: mapboxgl.Marker ) => marker.remove() );
        }
    }
}

export default PoiMarkerLayerOption;
