import Logger from '../logging/logger';
import ReadMore from '../property/read-more';

function onPopupOpenDirectory( event ) {
    var detail = event.detail || {};

    Logger.info( 'Event Triggered: onPopupOpenDirectory', detail );

    var container = detail.container || document;

    // Initialise Maps
    document.dispatchEvent( new CustomEvent( 'twc:init-map', {
        bubbles: true,
        detail: {
            container: container,
        },
    } ) );

    // Initialise "Read More" button
    var description = container.querySelector( '.directory-description' );
    if ( description && description.dataset.readMoreText ) {
        description.readMore = new ReadMore( description, {
            readMoreText: description.dataset.readMoreText,
            shortenClass: 'readmore-shorten',
            textLimit: 600,
        } );
    }
}

export default onPopupOpenDirectory;
