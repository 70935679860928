import '../polyfills';
import GlobalContext from '../bootstrap';
import itemDetailMaps from '../maps/item-map';
import initPagination from '../pagination';
import resetableField from '../forms/resetable-field';
import initPrintable from '../print';
import initTypeaheads from '../typeahead';
import initImagePopups from '../editor-content/img-popout';
import initOneTrustPreferenceButtons from '../onetrust';
import onPopupOpenDirectory from '../events/popup-open-directory';
import popupHandler from '../popups/handler';
import onPopupOpenLocation from '../events/popup-open-location';
import { onUpdateSavedStatus, updateSavedCounter } from '../user/saved';

class Page extends GlobalContext {
    constructor() {
        super();

        this._registerEvents();

        this._initialiseComponents();
    }

    _registerEvents(): void {
        window.addEventListener( 'twc:popup:open-location', onPopupOpenLocation );

        window.addEventListener( 'twc:popup:open-directory', onPopupOpenDirectory );

        window.addEventListener( 'twc:navigation', popupHandler );

        window.addEventListener( 'twc:update-saved-status', onUpdateSavedStatus );

        window.addEventListener( 'twc:update-saved-counter', updateSavedCounter );
    }

    _initialiseComponents(): void {
        initTypeaheads();

        resetableField();

        itemDetailMaps();

        initPrintable();

        initImagePopups();

        initOneTrustPreferenceButtons();

        if ( document.body.classList.contains( 'twc__pagination' ) ) {
            initPagination( document.body );
        }
    }
}

new Page();
