import { LayerOption, MarkerGroup } from '../models';
import PoiMarkerLayerOption from './marker-layer-option';
import PoiGeoJsonLayerOption from './geojson-layer-option';

type PoiSelectorOptions = {
    container: HTMLElement;
    groups: Record<string, MarkerGroup>;
    labelText?: string;
    placeholderText?: string;
}

class PoiSelector implements mapboxgl.IControl {
    _container: HTMLElement;
    _map: mapboxgl.Map;
    _options: PoiSelectorOptions;
    _select: HTMLSelectElement;
    _selectOptions: Array<LayerOption> = [];

    constructor( options: PoiSelectorOptions ) {
        this._options = options;

        Object.keys( this._options.groups ).forEach( ( groupName: string ) => {
            let option: LayerOption | null = null;

            if ( this._options.groups[ groupName ].markers && this._options.groups[ groupName ].markers.length > 0 ) {
                option = new PoiMarkerLayerOption( this, this._options.groups[ groupName ] );
            }

            if ( this._options.groups[ groupName ].geoJsonURL && this._options.groups[ groupName ].geoJsonURL.length > 0 ) {
                option = new PoiGeoJsonLayerOption( this, this._options.groups[ groupName ] );
            }

            if ( option === null ) {
                throw new Error( 'unknown layer option' );
            }

            this._selectOptions = [
                ...this._selectOptions,
                option,
            ];
        } );
    }

    onAdd( map: mapboxgl.Map ): HTMLElement {
        this._map = map;

        this._container = document.createElement( 'div' );
        this._container.className = 'mapboxgl-ctrl locationmap__layerselectcontainer';

        if ( this._options.labelText ) {
            const label = document.createElement( 'label' );
            label.setAttribute( 'for', 'poi-layer-select' );
            label.className = 'locationmap__layerselectlabel';
            label.innerHTML = this._options.labelText;
            this._container.appendChild( label );
        }

        const selectWrap = document.createElement( 'div' );
        selectWrap.className = 'search-select-wrap gray';

        this._select = document.createElement( 'select' );
        this._select.id = 'poi-layer-select';
        this._select.name = 'poiLayer';
        this._select.className = 'locationmap__layerselect search-select';
        this._select.addEventListener( 'change', this._onChange.bind( this ) );

        selectWrap.appendChild( this._select );

        if ( this._options.placeholderText ) {
            const placeholderOption = document.createElement( 'option' );
            placeholderOption.innerHTML = this._options.placeholderText;
            this._select.appendChild( placeholderOption );
        }

        this._container.appendChild( selectWrap );

        this._selectOptions.forEach( ( option: LayerOption ) => this._select.appendChild( option.onAdd( this._map ) ) );

        this._options.container.addEventListener( 'click', this._onMarkerLinkClick.bind( this ) );

        this._options.container.appendChild( this._container );

        return this._container;
    }

    onRemove( map: mapboxgl.Map ): void {
        this._selectOptions.forEach( ( control: LayerOption ) => control.onRemove( this._map ) );
        this._container.remove();
    }

    deactivateAll(): void {
        this._selectOptions.forEach( ( control: LayerOption ) => control.deactivate() );
    }

    _onChange( event: Event ): void {
        const reset = (): void => {
            this._selectOptions.forEach( ( option: LayerOption ) => option.deactivate() );
            this._select.classList.remove( 'has-value' );

            this._select.dispatchEvent( new CustomEvent( 'location-map:hiding-poi', {
                bubbles: true,
            } ) );
        };

        const selectValue = this._select.options[ this._select.selectedIndex ].value;

        if ( !selectValue ) {
            reset();
            return;
        }

        const selected = this._selectOptions.find( ( option: LayerOption ) => option.id === selectValue );

        if ( !selected ) {
            reset();
            return;
        }

        selected.toggle();

        this._select.classList.add( 'has-value' );

        this._select.dispatchEvent( new CustomEvent( 'location-map:showing-poi', {
            bubbles: true,
        } ) );
    }

    _onMarkerLinkClick( event: Event ): void {
        if ( !event.target ) {
            return;
        }

        let element = event.target as HTMLElement;

        if ( !element.matches( '.locationmarkerlink' ) ) {
            return;
        }

        element = element as HTMLAnchorElement;

        event.preventDefault();

        const targetSelector = element.getAttribute( 'href' );

        if ( !targetSelector ) {
            return;
        }

        const targetElement: HTMLElement | null = document.querySelector( targetSelector );

        if ( !targetElement ) {
            return;
        }

        const elementToScroll: HTMLElement | null = document.querySelector( '#page-popup-container .pagepop__scrollcontainer' );

        if ( !elementToScroll ) {
            return;
        }

        if ( targetElement.offsetParent === null ) {
            const parent = targetElement.closest( '.locationpois__list' );

            if ( !parent ) {
                return;
            }

            const btn = parent.querySelector( '.locationpoitoggle' );

            if ( !btn ) {
                return;
            }

            parent.querySelectorAll( 'li' ).forEach( ( li ) => li.classList.remove( 'd-none' ) );
            btn.classList.add( 'locationpoitoggle--open', 'listexpander--open' );
        }

        const rect = targetElement.getBoundingClientRect();

        const header = document.querySelector( '#page-popup-container .pagepopup__header' ) as HTMLElement;

        const position = rect.top - header.offsetHeight;

        window.requestAnimationFrame( () => elementToScroll.scrollTop = position );
    }
}

export default PoiSelector;
