class LocationIndicator {
    constructor() {
        this.map = undefined;
        this.txt = undefined;
        this.txtContainer = undefined;
    }

    onAdd( map ) {
        this.map = map;

        this.container = document.createElement( 'div' );
        this.container.className = 'mapboxgl-ctrl-location-indicator';

        this.txt = document.createElement( 'p' );
        this.txt.className = 'mapboxgl-ctrl-location-indicator-txt disabled';
        this.txt.dataset.blocked = 'false';

        this.container.appendChild( this.txt );

        return this.container;
    }

    update( html, options = {} ) {
        options = {
            ...LocationIndicator.autohideDefaults,
            ...options,
        };

        if ( this.autohide ) {
            window.clearTimeout( this.autohide );
        }

        if ( !html || html.length === 0 ) {
            this.txt.classList.add( 'disabled' );
        } else {
            this.txt.classList.remove( 'disabled' );
        }

        this.txt.innerHTML = html;

        if ( options.autohide ) {
            this.autohide = window.setTimeout( () => {
                this.clear( true );
            }, options.timeout );
        }

        if ( options.block ) {
            this.txt.dataset.blocked = 'true';
        } else {
            this.txt.dataset.blocked = 'false';
        }
    }

    clear( force = false ) {
        if ( !force && this.txt.dataset.blocked && this.txt.dataset.blocked === 'true' ) {
            return;
        }

        this.txt.innerHTML = '';
        this.txt.classList.add( 'disabled' );
    }

    onRemove() {
        this.map = undefined;
        this.container.remove();
        this.container = undefined;
    }
}

LocationIndicator.autohideDefaults = {
    autohide: false,
    timeout: 5000,
    block: false,
};

export default LocationIndicator;
